import React from 'react';
import { A } from 'components/A';
import { NetworkIcon } from 'elements/NetworkIcon';
import { Post } from 'lib/types';
import { getPostAccountLinkAttributes } from './link';

interface Props {
  post: Post;
  size?: 'inherit' | 'medium' | 'small' | 'large';
  linkToProfile?: boolean;
  noLink?: boolean;
  greyOutCard?: boolean;
}

export const PostAccount = ({
  post,
  size = 'small',
  linkToProfile,
  noLink,
  greyOutCard,
}: Props) => {
  const {
    account,
    channelId,
    network,
    url,
    accountUser,
  } = post;

  const { href, target } = getPostAccountLinkAttributes(
    linkToProfile || false,
    (!accountUser?.isHidden && accountUser?.termsAcknowledged) || false,
    accountUser?.username || account, // fall back to account if no username
    network,
    account,
    channelId,
    url,
  );

  const renderContent = () => {
    const classes = size === 'large' ? 'text-xl' : '';
    return (
      <div className="flex items-center">
        <NetworkIcon fontSize={size} network={network} iconColor={greyOutCard ? 'light' : undefined} />
        <div className={`pl-1 font-bold overflow-ellipsis overflow-hidden ${classes}`}>
          @
          {account}
        </div>
      </div>
    );
  };

  if (noLink) return renderContent();

  return (
    <A
      href={href}
      target={target}
      className="whitespace-nowrap overflow-ellipsis overflow-hidden"
      title={account}
    >
      {renderContent()}
    </A>
  );
};
